body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont,
    "Segoe UI, " Roboto ", sans-serif;", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-Regular.ttf") format(truetype);
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-Thin.ttf") format(truetype);
  font-weight: 100;
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-ExtraLight.ttf") format(truetype);
  font-weight: 200;
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-Light.ttf") format(truetype);
  font-weight: 300;
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-Regular.ttf") format(truetype);
  font-weight: 400;
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-Medium.ttf") format(truetype);
  font-weight: 500;
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-SemiBold.ttf") format(truetype);
  font-weight: 600;
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-Bold.ttf") format(truetype);
  font-weight: 700;
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-ExtraBold.ttf") format(truetype);
  font-weight: 800;
}
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSansThai-Black.ttf") format(truetype);
  font-weight: 900;
}

@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Medium.ttf") format(truetype);
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-ExtraLight.ttf") format(truetype);
  font-weight: 200;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Light.ttf") format(truetype);
  font-weight: 300;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Regular.ttf") format(truetype);
  font-weight: 400;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Medium.ttf") format(truetype);
  font-weight: 500;
}
@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-SemiBold.ttf") format(truetype);
  font-weight: 600;
}

@font-face {
  font-family: "Mitr";
  src: url("./fonts/Mitr-Bold.ttf") format(truetype);
  font-weight: 700;
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Medium.ttf") format(truetype);
}

@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-ExtraLight.ttf") format(truetype);
  font-weight: 200;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Light.ttf") format(truetype);
  font-weight: 300;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Regular.ttf") format(truetype);
  font-weight: 400;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Medium.ttf") format(truetype);
  font-weight: 500;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-SemiBold.ttf") format(truetype);
  font-weight: 600;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Bold.ttf") format(truetype);
  font-weight: 700;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-ExtraBold.ttf") format(truetype);
  font-weight: 800;
}
@font-face {
  font-family: "Nunito";
  src: url("./fonts/Nunito-Black.ttf") format(truetype);
  font-weight: 900;
}

body::-webkit-scrollbar {
  display: none;
}
.fb-button {
  padding: 10px 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  transition: all 0.5;
  background-color: #3b589e;
  color: #ffffff;
  font-size: 36px;
  cursor: pointer;
  border: none;
  justify-content: center;
}

.ant-spin-container {
  background: #e2e2e2;
}
.ant-layout-content {
  width: 100%;
}

*:not(.fa) {
  /* font-family: "Nunito", "Mitr"; */
  font-family: "NotoSans";
  font-variant-numeric: proportional-nums;
  font-weight: 300;
}

/* @font-face {
  font-family: "Segoe UI";
  font-weight: 200;
  src: url("fonts/Segoe UI.ttf") format("truetype");
}
@font-face {
  font-family: "Segoe UI";
  font-weight: 300;
  src: url("fonts/Segoe UI.ttf") format("truetype");
}
@font-face {
  font-family: "Segoe UI";
  font-weight: 400;
  src: url("fonts/Segoe UI.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI";
  font-weight: 500;
  font-style: italic;
  src: url("fonts/Segoe UI.ttf") format("truetype");
}
@font-face {
  font-family: "Segoe UI";
  font-weight: 600;
  font-style: italic;
  src: url("fonts/Segoe UI.ttf") format("truetype");
}
@font-face {
  font-family: "Segoe UI";
  font-weight: 700;
  src: url("fonts/Segoe UI Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Segoe UI";
  font-style: italic;
  font-weight: 400;
  src: url("fonts/Segoe UI Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Segoe UI";
  font-style: italic;
  font-weight: 700;
  src: url("fonts/Segoe UI Bold Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Leelawadee UI";
  font-weight: 200;
  src: url("fonts/LeelawUI.ttf") format("truetype"),
    url("fonts/leelauib.png") format("png");
}
@font-face {
  font-family: "Leelawadee UI";
  font-weight: 300;
  src: url("fonts/LeelawUI.ttf") format("truetype"),
    url("fonts/leelauib.png") format("png");
}
@font-face {
  font-family: "Leelawadee UI";
  font-weight: 400;
  src: url("fonts/LeelawUI.ttf") format("truetype"),
    url("fonts/leelauib.png") format("png");
}
@font-face {
  font-family: "Leelawadee UI";
  font-weight: 500;
  src: url("fonts/LeelawUI.ttf") format("truetype"),
    url("fonts/leelauib.png") format("png");
}
@font-face {
  font-family: "Leelawadee UI";
  font-weight: 600;
  font-style: italic;
  src: url("fonts/LeelawUI.ttf") format("truetype"),
    url("fonts/leelauib.png") format("png");
}
@font-face {
  font-family: "Leelawadee UI";
  font-weight: 700;
  font-style: italic;
  src: url("fonts/LeelawUI.ttf") format("truetype"),
    url("fonts/leelauib.png") format("png");
}
@font-face {
  font-family: "Leelawadee UI";
  font-style: italic;
  font-weight: 400;
  src: url("fonts/LeelawUI.ttf") format("truetype"),
    url("fonts/leelauib.png") format("png");
}
@font-face {
  font-family: "Leelawadee UI";
  font-style: italic;
  font-weight: 700;
  src: url("fonts/LeelawUI.ttf") format("truetype"),
    url("fonts/leelauib.png") format("png");
} */

.h-p-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
}

.h-p-body {
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: calc(100vw - 24px);
  margin: auto;
  padding: 28px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 456px;
}

.h-p-title {
  margin: 24px 0 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: rgb(152, 152, 152);
}
.h-p-desc {
  font-weight: 600;
  font-size: 16px;
  color: rgb(0, 0, 0);
}
.h-p-button {
  margin-top: 30px;
  font-weight: 600;
  font-size: 14;
  border-color: unset !important;
}
.h-p-loading-item {
  margin-bottom: 16px;
}

.h-p-shop-name {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.ant-select-dropdown-menu-item-active:not(
    .ant-select-dropdown-menu-item-disabled
  ) {
  background-color: #f7f7f8 !important;
}

/* Close iframe */
/* iframe {
  pointer-events: none;
} */

.ant-time-picker-panel .ant-time-picker-panel-select {
  width: 120px;
}
.ant-time-picker-panel .ant-time-picker-panel-select li {
  width: 120px;
}
.ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
  max-width: 100% !important;
}
